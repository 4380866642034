
function closeDramscan() {
  showAlert("Close Dramscan?", {auth:true,authKey:"exitLevel",focus:"username",type:"info",canClose:true,callback:function(authorised) {
      if(authorised) {
        window.close();
      }
    }});
}

$(document).ready(function () {

  var outputStatus = function (message) {
    $('#cacheInfo').val(message);
  };

  var scannedLogin = false,
    scannerId = (localStorage.getItem("scannerid"))? localStorage.getItem("scannerid"):"";

  $("#login").on("click", function (e) {
    var username, password;
    e.preventDefault();
    $(this).attr("disabled", "disabled");
    clearSession(false);
    if(scannedLogin) {
      var prefix = localStorage.getItem("settings/scanPrefix");
      if(prefix !== "") {
        username = document.getElementById("username").value.substring(prefix.length);
      }
    } else {
      username = document.getElementById("username").value;
    }
    onlineLogin(username, document.getElementById("password").value);
  });

  window.addEventListener('dr-cache', function(event) {
    outputStatus(event.detail.text);
  });

  // register the AppCache events so that we can watch them on the login page.
  registerAppCacheEvents();

  checkNetworkStatus(true, true);
  disableBackButton();
  attachInputEvents();

  if (checkCacheCapability() === CachingCapability.None) {
    outputStatus('No Offline Support');
  }

  document.getElementById("username").focus();

  // Add events for username, password.
    document.getElementById("username").onkeypress = function (e) {
      var keyCode = e.keyCode;
      if (keyCode == '13') {
        // Enter pressed
        scannedLogin = false;
        document.getElementById("password").focus();
        return false;
      }
      if (keyCode == '58') {
        // : pressed
        scannedLogin = true;
        document.getElementById("password").focus();
        return false;
      }
    };
    document.getElementById("password").onkeypress = function (e) {
      if (e.keyCode == 13) {
        $("#login").click();
      }
    };

  countStoredTransactions(function(numberOfTransactions) {
    $('#transactioninfo').attr("disabled", "disabled").val("Transactions: " + numberOfTransactions);
  });

  if (sessionStorage.getItem("logoutMessage")) {
    var message = sessionStorage.getItem("logoutMessage");
    sessionStorage.removeItem("logoutMessage");
    showAlert(message,{type:"info",focus:"username"});
  }

  $("#footerScannerId").html(scannerId);
  /**
   * @param {configLoadedCallback}
   */
  getConfig(function(localConfig) {
    $("#footerVersion").html(localConfig.applicationVersion);
    $("#footerEnvironment").html(localConfig.environmentName);
  });

  //initialise time
  var today = new Date();
  var hours = padNumber(2,String(today.getHours()));
  var minutes = padNumber(2,String(today.getMinutes()));
  var seconds = today.getSeconds();
  $("#time").val(hours + ":" + minutes);

  setTimeout(updateTime,60000-(seconds*1000));

  function updateTime() {
    var today = new Date();
    var hours = padNumber(2,String(today.getHours()));
    var minutes = padNumber(2,String(today.getMinutes()));
    $("#time").val(hours + ":" + minutes);
    var timeout = setTimeout(updateTime,60000);
  }

});